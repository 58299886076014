<template>
  <v-combobox v-model="selectedTag" :items="savedTags" :search-input.sync="search" hide-selected solo
    :label="$t('tag.addTagLabel')" item-text="name" :hint="$t('tag.addTagHint')" persistent-hint @change="onChange"
    return-object clearable :disabled="disabled">
    <template v-slot:item="{ item }">
      <tag-chip :tag="item"></tag-chip>
    </template>
    <template v-slot:selection="{ item }">
      <tag-chip :tag="item"></tag-chip>
    </template>
    <template v-slot:no-data>
      <v-list-item>
        {{ $t('tag.noTagDescription') }}
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
import TagChip from "../chip/TagChip.vue"
import { mapFields } from 'vuex-map-fields'
import { mapActions } from "vuex"
export default {
  name: 'add-tag-select',
  components: { TagChip },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      search: null,
      addTagColor: "primary",
    }
  },
  computed: {
    ...mapFields("selectedUserAddress", { selectedTag: "selectedUserAddress.tag", userAddressType: "selectedUserAddress.userAddressType" }),
    ...mapFields("userTags", {savedTags: "tags"}),
  },
  methods: {
    ...mapActions("userTags", ["fetchTags"]),
    onChange(entry) {
      if (typeof entry === 'string' && entry.trim()) {
        const selectedUserTag = this.savedTags.find(userTag => userTag.name === entry)
        if (selectedUserTag) {
          this.selectedTag = selectedUserTag
        } else {
          this.selectedTag = { id: null, name: entry, color: this.addTagColor }
        }
      }
    },
  },
  watch: {
    userAddressType(newValue, oldValue){
      if (newValue != oldValue && newValue == 'WATCHLIST'){
        this.selectedTag = null
      }
    } 
  },
  created(){
    this.fetchTags()
  }
}
</script>

<style>
</style>