<template>
  <div>
    <v-icon :color="tag.color" class="mr-2">
      {{ tag.id ? 'mdi-tag' : 'mdi-tag-plus' }}
    </v-icon>
    {{ tag.name }}
  </div>
</template>

<script>
export default {
  name: 'tag-chip',
  props: {
    tag: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {}
  },
  methods: {
    clickOnChip(){
      this.$emit("clickOnChip", this.tag)
    }
  }
}
</script>

<style>
</style>